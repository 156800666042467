import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Banner from "./Components/Banner";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import AboutusPage from "./Pages/AboutusPage";
import ContactusPage from "./Pages/ContactusPage";
import DigitalMarketingPage from "./Pages/DigitalMarketingPage";
import HomePage from "./Pages/HomePage";
import LogoDesgningPage from "./Pages/LogoDesgningPage";
import MobileAppDevelopmentPage from "./Pages/MobileAppDevelopmentPage";
import WebsiteDesgningPage from "./Pages/WebsiteDesigningPage";
import WebsiteDevelopmentPage from "./Pages/WebsiteDevelopmentPage";

import "./Styles/main.scss";

function App() {
    return (
        <BrowserRouter>
            <Header />
            <Banner />
            <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route exact path="/about-us" element={<AboutusPage />} />
                <Route exact path="/contact-us" element={<ContactusPage />} />
                <Route exact path="/website-designing" element={<WebsiteDesgningPage />} />
                <Route exact path="/website-development" element={<WebsiteDevelopmentPage />} />
                <Route exact path="/logo-designing" element={<LogoDesgningPage />} />
                <Route exact path="/digital-marketing" element={<DigitalMarketingPage />} />
                <Route exact path="/mobile-application-development" element={<MobileAppDevelopmentPage />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}

export default App;
