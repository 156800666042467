import React from "react";

import website_designing from "../../Assets/images/website_design.webp";
import website_development from "../../Assets/images/web_development.webp";
import graphic_design from "../../Assets/images/graphic_design.webp";

import { FaLaptopCode } from "react-icons/fa";

import "./MobileAppDevelopmentPage.scss";
import { Helmet } from "react-helmet";

const MobileAppDevelopmentPageComponent = () => {
    const renderMetaData = () => (
        <Helmet>
            <title>Mobile App Development Services | Mobile Application Development Company - Vojwebtech</title>
            <meta name="description" content="Are you looking for a mobile app development company? Vojwebtech provides the best application development services which can help your business in building your brand & creating awareness." />
            <meta name="robots" content="max-image-preview:large" />
            <link rel="canonical" href="https://www.vojwebtech.com/mobile-application-development/" />
        </Helmet>
    );

    const renderHeading = () => (
        <div className="MobileAppDevelopmentPage-Heading">
            Mobile Application Developement : <span>VOJ WebTech</span>
        </div>
    );

    const renderServiceHeading = () => (
        <div className="MobileAppDevelopmentPage-Heading">
            <span>VOJ WebTech</span> : Mobile Application Development Services
        </div>
    );

    const renderWhyusHeading = () => (
        <div className="MobileAppDevelopmentPage-Heading">
            Why <span>VOJWebTech ?</span>
        </div>
    );

    const renderText = () => (
        <>
            <p className="MobileAppDevelopmentPage-Content">
                If yes, then during this technocratic era you need a conventional approach. These days, everything is possible to do anywhere, anytime just by using a small device : Mobile Phone. Moreover, the basicity of making tasks easy under a small screen is through applications inside it. That is why, these mobile applications are a vital part of every person’s life. Therefore, bringing the optimum mobile application development services would definitely fulfill all the requirements of yours and your clients. We are here for you along with the latest technologies which gather all sturdy platforms for developing customised mobile applications.
            </p>
            <p className="MobileAppDevelopmentPage-Content">
                The usage of Artificial Intelligence, IOT and Machine Language is done strategically by us to provide allurance, functionality, and automation. Moreover, we are the combination of a reputed mobile application development firm and with skilled experienced developers. We work not only for a well settled business, but also for custom applications for those who just want a new-start.
            </p>  
        </>
    );

    const renderWhyusText = () => (
        <>
            <p className="MobileAppDevelopmentPage-Content">
            We aim to boost your business approach and digital profits. Our applications are designed to enhance the value of your organisation at a large pace. Our mobile app development services are transparent, of a high standard, and rapid. Therefore, take a look at the profits you will get while working with us.
            </p>
            <p className="MobileAppDevelopmentPage-Content">
            You will get the best business value in return through our mobile app development services. Our objective is to reflect your business marvellously and call more to the prospects of your firm.
            </p>
            <p className="MobileAppDevelopmentPage-Content">
            Secondly, our accurate mobile app development services cater you to be under the limelight of professionals and stay ahead in this competitive era. Our customised way of developing applications offer you the functionality and agility for making your business smart.
            </p>
            <p className="MobileAppDevelopmentPage-Content">
            Our process of working is wholly transparent. Moreover, you will have a complete control of observations and working with it. The progress chart and feedback columns will also be under your access even after launching the applications.
            </p>
        </>
    );

    const renderServiceText = () => (
        <p className="MobileAppDevelopmentPage-Content">
            Mobile applications services by us provide you the featured merits of technology. Furthermore, we also add technologies such as cloud computing, big data and many more. Along with the changes in technical trends, our mobile app development services also get adapted accordingly. We have a team of professionals, who provide you myriad benefits as follows.
        </p>
    );

    const renderSubHeading = () => (
        <div className="MobileAppDevelopmentPage-SubHeading">
            Types of mobile application services we offer
        </div>
    );

    const renderServices = () => (
        <div className="MobileAppDevelopmentPage-Services">
            <div className="MobileAppDevelopmentPage-Service">
                <img width="0" height="0" className="MobileAppDevelopmentPage-Service-Icon" src={ website_designing } alt="Android app development" />
                <div className="MobileAppDevelopmentPage-Service-Heading">Android app development</div>
                <div className="MobileAppDevelopmentPage-Service-Content">We offer the services of Android mobile application development, who incorporate the advanced quality and level of modernised standards based upon all key aspects. Moreover, we also bring certainty that the application holds a whole engaging interface. Satisfactory results will be in your hands after observing our mobile application development services.</div>
            </div>
            <div className="MobileAppDevelopmentPage-Service">
                <img width="0" height="0" className="MobileAppDevelopmentPage-Service-Icon" src={ website_development } alt="IOS Application development" />
                <div className="MobileAppDevelopmentPage-Service-Heading">IOS Application development</div>
                <div className="MobileAppDevelopmentPage-Service-Content">The applications for iPhone and iPads are acquiring a large space among the consumers. Furthermore, If your target audience relates to that horizon, then our iOS mobile application development services are meant for you. However, we develop competitive iOS apps that would meet all of your company’s requirements. Our iOS apps services are innovative in ideas which would create a greater conversion rate for your firm.</div>
            </div>
            <div className="MobileAppDevelopmentPage-Service">
                <img width="0" height="0" className="MobileAppDevelopmentPage-Service-Icon" src={ graphic_design } alt="Applications as Hybrid" />
                <div className="MobileAppDevelopmentPage-Service-Heading">Applications as Hybrid</div>
                <div className="MobileAppDevelopmentPage-Service-Content">Hybrid applications are highly suitable for audiences who get engaged in both android and ios services. Moreover, these applications are for those firms, who hanker to attain features of mobile and web applications both.</div>
            </div>
        </div>
    );

    const renderServicesList = () => (
        <div className="MobileAppDevelopmentPage-ServicesList">
            <div className="MobileAppDevelopmentPage-ServicesList-Item">
                <span><FaLaptopCode /></span> Push Notifications benefits
            </div>
            <div className="MobileAppDevelopmentPage-ServicesList-Item">
                <span><FaLaptopCode /></span> GPS and location tracing features
            </div>
            <div className="MobileAppDevelopmentPage-ServicesList-Item">
                <span><FaLaptopCode /></span> Implementation and Integration of Social Media platforms
            </div>
            <div className="MobileAppDevelopmentPage-ServicesList-Item">
                <span><FaLaptopCode /></span> Chatting and messages options
            </div>
            <div className="MobileAppDevelopmentPage-ServicesList-Item">
                <span><FaLaptopCode /></span> Offline Functions
            </div>
            <div className="MobileAppDevelopmentPage-ServicesList-Item">
                <span><FaLaptopCode /></span> Mobile Analytics
            </div>
            <div className="MobileAppDevelopmentPage-ServicesList-Item">
                <span><FaLaptopCode /></span> Augmented Reality
            </div>
            <div className="MobileAppDevelopmentPage-ServicesList-Item">
                <span><FaLaptopCode /></span> Virtual Reality
            </div>
            <div className="MobileAppDevelopmentPage-ServicesList-Item">
                <span><FaLaptopCode /></span> IoT features
            </div>
            <div className="MobileAppDevelopmentPage-ServicesList-Item">
                <span><FaLaptopCode /></span> Many more …
            </div>
        </div>
    );

    return (
        <div className="MobileAppDevelopmentPage">
            <div className="MobileAppDevelopmentPage-Container">
                { renderMetaData() }
                { renderHeading() }
                { renderText() }
                { renderSubHeading() }
                { renderServices() }
                { renderServiceHeading() }
                { renderServiceText() }
                { renderServicesList() }
                { renderWhyusHeading() }
                { renderWhyusText() }
            </div>
        </div>
    );
};

export default MobileAppDevelopmentPageComponent;