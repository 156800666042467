import React from "react";

import "./WebsiteDevelopmentPage.scss";
import { FaLaptopCode } from "react-icons/fa";
import { Helmet } from "react-helmet";

const WebsiteDevelopmentPageComponent = () => {
    const renderMetaData = () => (
        <Helmet>
            <title>Web Development Company | Web App Development Services - Vojwebtech</title>
            <meta name="description" content="Vojwebtech is a web development company in India that offers complete website development services. We have expertise in developing custom web app solutions. Visit Now!" />
            <meta name="robots" content="max-image-preview:large" />
            <link rel="canonical" href="https://www.vojwebtech.com/website-development/" />
        </Helmet>
    );

    return (
        <div className="WebsiteDevelopmentPage">
            { renderMetaData() }
            <div className="WebsiteDevelopmentPage-Container">
                <div className="WebsiteDevelopmentPage-Heading">
                    Website Development : <span>VOJ WebTech</span>
                </div>
                <p className="WebsiteDevelopmentPage-Content">
                    Unique website development technologies are in vogue every passing day. Furthermore, website application development has come to be an essential function with the passage of time. Due to the fact that website development enables a streamlined procedure that brings the digital presence of manual businesses to everyone at any given time.
                </p>
                <p className="WebsiteDevelopmentPage-Content">
                    There are various web development technologies that are in vogue, such as AJAX, ASP.NET, Action Script, CSS, CGI, ColdFusion, Java, JavaScript, HTML, Visual Lansa, Lasso, Node.js, Perl, PHP, Python, Ruby, MongoDB.
                </p>
                <p className="WebsiteDevelopmentPage-Content">
                    These updated website development services assist particular features which maintain online availability of business websites, number of business models, accessibility on various platforms, and many more.
                </p>
                <p className="WebsiteDevelopmentPage-Content">
                    Web Development is quite simple with these platforms which encompass website development techniques that simplify novel web development.
                </p>
                <p className="WebsiteDevelopmentPage-Content">
                    These frameworks hold the collaborative development of web services, advanced resources, and interfaces.
                </p>
                <p className="WebsiteDevelopmentPage-Content">
                    The basic aim of these platforms is to activate simplicity to focus only on a particular task of maintenance of websites online. The most noteworthy advantage of these frameworks is to minimize errors by adding simplicity to the integration process.
                </p>
                <p className="WebsiteDevelopmentPage-Content">
                    Web Development Tools make it simple to maintain the workflow of a website. These tools not only help in the candid development of web applications but also focus on the testing of intermediate interfaces of the website creation.
                </p>
                <p className="WebsiteDevelopmentPage-Content">
                    The huge majority of web application development tools are to find add-ons or pre-existing features of search engines such as Google Chrome, Firefox, Safari, and many more. A few of the most used web development tools include:
                </p>
                <div className="WebsiteDevelopmentPage-ServicesList">
                    <div className="WebsiteDevelopmentPage-ServicesList-Item">
                        <span><FaLaptopCode /></span> JavaScript libraries: most paramount libraries of JavaScript include jQuery, React, Angular, VueJS, NextJS, ElectronJS Moment.js and many more.
                    </div>
                    <div className="WebsiteDevelopmentPage-ServicesList-Item">
                        <span><FaLaptopCode /></span> Frontend platforms: Built from HTML, CSS, or JavaScript, these frameworks also include Bootstrap, Semantic UI and few more.
                    </div>
                    <div className="WebsiteDevelopmentPage-ServicesList-Item">
                        <span><FaLaptopCode /></span> Task runners: automation of the website development workflow.
                    </div>
                    <div className="WebsiteDevelopmentPage-ServicesList-Item">
                        <span><FaLaptopCode /></span> Programming languages: The backbone of all website application development is the programming language. Such as PHP, HTML5, Python, Ruby, NodeJS, and JavaScript.
                    </div>
                </div>
                <p className="WebsiteDevelopmentPage-Content">
                    Databases are collections of data that can be created, altered, managed, and retrieved for the working of the website application. Each approach to database design has its advantages and drawbacks, which should be taken into account when choosing the best option for your website.
                </p>
                <div className="WebsiteDevelopmentPage-ServicesList">
                    <div className="WebsiteDevelopmentPage-ServicesList-Item">
                        <span><FaLaptopCode /></span> SQL databases: SQL Databases are more structured and sturd.
                    </div>
                    <div className="WebsiteDevelopmentPage-ServicesList-Item">
                        <span><FaLaptopCode /></span> NoSQL databases: NoSQL databases are unstructured, and are more flexible.
                    </div>
                </div>
                <p className="WebsiteDevelopmentPage-Content">
                    Testing and Quality Assurance are critical in improving the functioning of websites. These tools help to correct errors or bugs in the system. They help to validate the functionality of the website, API, service, or any web interface.
                </p>
                <div className="WebsiteDevelopmentPage-Heading">
                    <span>VOJ WebTech</span> : For You
                </div>
            </div>
        </div>
    );
};

export default WebsiteDevelopmentPageComponent;