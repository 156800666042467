import React from "react";

import "./LogoDesgningPage.scss";
import { FaLaptopCode } from "react-icons/fa";
import { Helmet } from "react-helmet";

const LogoDesgningPageComponent = () => {
    const renderMetaData = () => (
        <Helmet>
            <title>Best Logo Designing Services in India - Vojwebtech</title>
            <meta name="description" content="Are you Searching for a Logo Designing Company? Vojwebtech provides trusted Logo designing services. We follow an adaptive agile approach to build mobile apps. Get a quote now!" />
            <meta name="robots" content="max-image-preview:large" />
            <link rel="canonical" href="https://www.vojwebtech.com/logo-designing/" />
        </Helmet>
    );

    const renderHeading = () => (
        <div className="LogoDesgningPage-Heading">
            Importance of Logo For Business : <span>VOJ WebTech</span>
        </div>
    );

    const renderText = () => (
        <>
            <p className="LogoDesgningPage-Content">
                The logo is a major part of giving the first impression to viewers who online interacts with your business company. If you have a business-based firm or you offer a particular service then you come to know about the importance of a good logo that represents your work and your identity. However, planning to design a logo has lots of questions. Have a clear glance at the following points to make it more clear.    
            </p>
            <p className="LogoDesgningPage-Content">
                The foremost thing is to know the need for a logo. First impressions are highly impactful in every circle of contact. Obviously, the first impression is responsible for the encouragement of more communications. In short, the logo of your company should be simple to understand and must reflect everything about your business.
            </p>  
            <p className="LogoDesgningPage-Content">
                If you are trying to give a short definition of your business then ask yourself some important and quality questions. Likewise, to explain your whole services in just a few words. Features of your company to your clients. These types of statements will definitely help you to focus on making a different image of your services in the virtual world.
            </p>  
            <p className="LogoDesgningPage-Content">
                By making a proper plan of designing a logo you can give a psychological vision to your viewers accurately. Plan the things, gather the requirements, implement your ideas, integrate them for meeting future expectations and that is all to make yourself under the spotlight of online presence.
            </p>
            <p className="LogoDesgningPage-Content">
                The logo of your brand shows each and every member of your team. It is not a thing to be done by a few. It brings harmony to the work community and gives an appropriate ambiance of work at the workplace.
            </p>
            <p className="LogoDesgningPage-Content">
                Choose the best out of the best styles and designs you desire to create for your company. Go through all types of catchy, rustic, classic, trendy, or antique types of logos. And, then shortlist the one which shows maximum suitability with your business.
            </p>
            <p className="LogoDesgningPage-Content">
                Colors speak to everyone at first sight. Therefore, you are required to give a priority to colors and designs which seems alluring to your target audience. Furthermore, must ensure that your logo is compatible with your business.
            </p>
        </>
    );

    const renderServiceHeading = () => (
        <div className="LogoDesgningPage-Heading">
            <span>VOJ WebTech</span> : Logo Designing Services
        </div>
    );

    const renderServicesList = () => (
        <div className="LogoDesgningPage-ServicesList">
            <div className="LogoDesgningPage-ServicesList-Item">
                <span><FaLaptopCode /></span> We are a team of members with ensured guidance which can bring out the best for your company.
            </div>
            <div className="LogoDesgningPage-ServicesList-Item">
                <span><FaLaptopCode /></span> We prefer designing and styling logos from the scratch.
            </div>
            <div className="LogoDesgningPage-ServicesList-Item">
                <span><FaLaptopCode /></span> We have advanced gradients, templates and logo designing tools.
            </div>
            <div className="LogoDesgningPage-ServicesList-Item">
                <span><FaLaptopCode /></span> We are here to support you from the beginning till the ending of designing a logo which reflects your viewers.
            </div>
            <div className="LogoDesgningPage-ServicesList-Item">
                <span><FaLaptopCode /></span> We aspire to Mission and vision at one glance.
            </div>
            <div className="LogoDesgningPage-ServicesList-Item">
                <span><FaLaptopCode /></span> You will have privilege to control your logo design once we give you credentials.
            </div>
            <div className="LogoDesgningPage-ServicesList-Item">
                <span><FaLaptopCode /></span> Capture the authenticity of your business with our logo designing services.
            </div>
        </div>
    );

    return (
        <div className="LogoDesgningPage">
            <div className="LogoDesgningPage-Container">
                { renderMetaData() }
                { renderHeading() }
                { renderText() }
                { renderServiceHeading() }
                { renderServicesList() }
            </div>
        </div>
    );
};

export default LogoDesgningPageComponent;