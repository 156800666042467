import React from "react";

import aboutusimage from "../../Assets/images/about_voj.webp";
import website_design from "../../Assets/images/website_design.webp";
import website_development from "../../Assets/images/web_development.webp";
import logo_design from "../../Assets/images/logo_design.webp";
import graphic_design from "../../Assets/images/graphic_design.webp";
import digital_marketing from "../../Assets/images/digital_marketing.webp";
import content_writing from "../../Assets/images/content_writing.webp";
import contact_us from "../../Assets/images/contact-us.webp";
import { FaRegChartBar, FaPaintBrush, FaRegLightbulb, FaRegFileCode, FaRocket } from "react-icons/fa";

import "./HomePage.scss";
import { Helmet } from "react-helmet";

const HomePageComponent = () => {
    const renderMetaData = () => (
        <Helmet>
            <title>Web Design Company | Web &amp; Mobile App Development Services - Vojwebtech</title>
            <meta name="description" content="Vojwebtech is a professional website design company offering you the custom web and mobile app development services at reasonable cost. Contact one of the best website development companies." />
            <meta name="robots" content="max-image-preview:large" />
            <link rel="canonical" href="https://www.vojwebtech.com/" />
        </Helmet>
    );

    const renderAboutSection = () => (
        <div className="HomePage-Aboutus">
            <div className="HomePage-Aboutus-Container">
                <div className="HomePage-Aboutus-Image">
                    <img width="0" height="0" src={ aboutusimage } alt="About Voj WebTech" />
                </div>
                <div className="HomePage-Aboutus-Content">
                    <div className="HomePage-Aboutus-Heading">About <span>VOJ WebTech</span></div>
                    <div className="HomePage-Aboutus-Text">VOJ Webtech offers a complete online solution to entrepreneurs, SMEs artists and innovators. Adroit services of Website designing, website developing, blogging, graphic designing, online marketing, SEO, PPC, social media content, online advertisement and much more are in the counts here. Therefore, make yourself meet the world, expand your business, attain more leads and shine like a dazzling star with our integrated digital services.</div>
                </div>
            </div>
        </div>
    );

    const renderServicesSection = () => (
        <div className="HomePage-Services">
            <div className="HomePage-Services-Container">
                <div className="HomePage-Services-SubHeading">Services</div>
                <div className="HomePage-Services-Heading">See What <span>We Can Do</span> For You</div>
                <div className="HomePage-Services-Items">
                    <div className="HomePage-Services-Item">
                        <img width="0" height="0" src={ website_design } alt="Website Design" className="HomePage-Services-Item-Image"/>
                        <div className="HomePage-Services-Item-Heading">Website Design</div>
                        <div className="HomePage-Services-Item-Text">
                            Every business needs an online face. Therefore,
                             having a wonderful website can make a huge profit to your business.
                        </div>
                    </div>
                    <div className="HomePage-Services-Item">
                        <img width="0" height="0" src={ website_development } alt="Web Application" className="HomePage-Services-Item-Image"/>
                        <div className="HomePage-Services-Item-Heading">Web Application</div>
                        <div className="HomePage-Services-Item-Text">
                            For boosting a business in this competitive world,
                             it should have an online face. Having a website helps
                              to make an online debut to your business.
                        </div>
                    </div>
                    <div className="HomePage-Services-Item">
                        <img width="0" height="0" src={ graphic_design } alt="Graphic Design" className="HomePage-Services-Item-Image"/>
                        <div className="HomePage-Services-Item-Heading">Graphic Design</div>
                        <div className="HomePage-Services-Item-Text">
                            Here is the designing solutions for your online and offline graphics and artistics work.
                        </div>
                    </div>
                    <div className="HomePage-Services-Item">
                        <img width="0" height="0" src={ digital_marketing } alt="Digital Marketing" className="HomePage-Services-Item-Image"/>
                        <div className="HomePage-Services-Item-Heading">Digital Marketing</div>
                        <div className="HomePage-Services-Item-Text">
                            It is not enough to just have a website and develop it to be in action.
                             Your business needs more. Therefore, we provide digital marketing 
                             services to help your business growth.
                        </div>
                    </div>
                    <div className="HomePage-Services-Item">
                        <img width="0" height="0" src={ content_writing } alt="Content Writing" className="HomePage-Services-Item-Image"/>
                        <div className="HomePage-Services-Item-Heading">Content Writing</div>
                        <div className="HomePage-Services-Item-Text">
                            We provide the effective, meaningful and noticeable
                             content for your websites. For ranking at the topmost 
                             counts in Search Engines, we can help your business to grab attention of customers.
                        </div>
                    </div>
                    <div className="HomePage-Services-Item">
                        <img width="0" height="0" src={ logo_design } alt="Logo Design" className="HomePage-Services-Item-Image"/>
                        <div className="HomePage-Services-Item-Heading">Logo Design</div>
                        <div className="HomePage-Services-Item-Text">
                            Your logo is the first image of your business. You can begin a new venture at this right place with a great looking and professional logo.
                        </div>
                    </div>
                </div>
                <div className="HomePage-Services-Text">
                    It takes all these services at a huge scale to elevate any business. 
                    So shine brightly and think <span>OUTSIDE THE BOX</span> with <span>VOJ Webtech</span>. 
                    Competitiveness brings great opportunities to every entrepreneur. 
                    Promote your products or services to the world, become the top businessman/businesswomen, 
                    attain more leads and boost your business growth.
                </div>
            </div>
        </div>
    );

    const renderWhyusSection = () => (
        <div className="HomePage-Whyus">
            <div className="HomePage-Whyus-Container">
                <div className="HomePage-Whyus-Heading">Why <span>Choose</span> Us?</div>
                <div className="HomePage-Whyus-Text">
                    We, the chosen professionals, will know how to develop and promote your business properly in order for you to get as far as you can in the market.
                </div>
                <div className="HomePage-Whyus-Items">
                    <div className="HomePage-Whyus-Item">
                        <img width="0" height="0" src={ website_design } alt="Extensive Experience" className="HomePage-Whyus-Item-Image"/>
                        <div className="HomePage-Whyus-Item-Heading">Extensive Experience</div>
                        <div className="HomePage-Whyus-Item-Text">
                            Companies rely on our many years of experience and our unique solutions prove it.
                        </div>
                    </div>
                    <div className="HomePage-Whyus-Item">
                        <img width="0" height="0" src={ website_development } alt="Strong team" className="HomePage-Whyus-Item-Image"/>
                        <div className="HomePage-Whyus-Item-Heading">Strong team</div>
                        <div className="HomePage-Whyus-Item-Text">
                            With an excellent team of professionals, your ideas will be realized in the best possible way and with top-notch performance.
                        </div>
                    </div>
                    <div className="HomePage-Whyus-Item">
                        <img width="0" height="0" src={ graphic_design } alt="Personal solutions" className="HomePage-Whyus-Item-Image"/>
                        <div className="HomePage-Whyus-Item-Heading">Personal solutions</div>
                        <div className="HomePage-Whyus-Item-Text">
                            Providing perfect solutions to meet your needs. Securing success on the way to your goals.
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );

    const renderHowWeDoSection = () => (
        <div className="HomePage-HowToSection">
            <div className="HomePage-HowToSection-Container">
                <div className="HomePage-HowToSection-SubHeading">Steps</div>
                <div className="HomePage-HowToSection-Heading">How We <span>Do It</span></div>
                <div className="HomePage-HowToSection-Content">
                    <div className="HomePage-HowToSection-Start" />
                    <div className="HomePage-HowToSection-Items">
                        <div className="HomePage-HowToSection-Item">
                            <div className="HomePage-HowToSection-Item-Label">01</div>
                            <div className="HomePage-HowToSection-Item-Icon"><FaRegLightbulb /></div>
                            <div className="HomePage-HowToSection-Item-Arrow" />
                            <div className="HomePage-HowToSection-Item-Content">
                                <div className="HomePage-HowToSection-Item-Content-Heading">Gather Info</div>
                                <div className="HomePage-HowToSection-Item-Content-Text">This stage is critical to the success of your business as it ensures all your requirements, including the future you envision.</div>
                            </div>
                        </div>
                        <div className="HomePage-HowToSection-Item">
                            <div className="HomePage-HowToSection-Item-Label">02</div>
                            <div className="HomePage-HowToSection-Item-Icon"><FaRegChartBar /></div>
                            <div className="HomePage-HowToSection-Item-Arrow" />
                            <div className="HomePage-HowToSection-Item-Content">
                                <div className="HomePage-HowToSection-Item-Content-Heading">Planning</div>
                                <div className="HomePage-HowToSection-Item-Content-Text">We begin this phase by creating a detail blueprint of your exact specifications to assist our designers at the designing stage.</div>
                            </div>
                        </div>
                        <div className="HomePage-HowToSection-Item">
                            <div className="HomePage-HowToSection-Item-Label">03</div>
                            <div className="HomePage-HowToSection-Item-Icon"><FaPaintBrush /></div>
                            <div className="HomePage-HowToSection-Item-Arrow" />
                            <div className="HomePage-HowToSection-Item-Content">
                                <div className="HomePage-HowToSection-Item-Content-Heading">Design</div>
                                <div className="HomePage-HowToSection-Item-Content-Text">Our designing professionals come up with a set of designs once they learn the client&apos;s requirements by gathering ideas from them, during the requirement gathering and planning phase.</div>
                            </div>
                        </div>
                        <div className="HomePage-HowToSection-Item">
                            <div className="HomePage-HowToSection-Item-Label">04</div>
                            <div className="HomePage-HowToSection-Item-Icon"><FaRegFileCode /></div>
                            <div className="HomePage-HowToSection-Item-Arrow" />
                            <div className="HomePage-HowToSection-Item-Content">
                                <div className="HomePage-HowToSection-Item-Content-Heading">Development</div>
                                <div className="HomePage-HowToSection-Item-Content-Text">Throughout our knowledge gathering, planning, and designing phase, we take care to understand the most optimal development environment in which we can meet all your requirements.</div>
                            </div>
                        </div>
                        <div className="HomePage-HowToSection-Item">
                            <div className="HomePage-HowToSection-Item-Label">05</div>
                            <div className="HomePage-HowToSection-Item-Icon"><FaRocket /></div>
                            <div className="HomePage-HowToSection-Item-Arrow" />
                            <div className="HomePage-HowToSection-Item-Content">
                                <div className="HomePage-HowToSection-Item-Content-Heading">Testing & Launch</div>
                                <div className="HomePage-HowToSection-Item-Content-Text">The professional web design and development services we offer help your business attract more visitors, keep them on your site, and improve your bottom line!</div>
                            </div>
                        </div>
                    </div>
                    <div className="HomePage-HowToSection-End" />
                </div>
            </div>
        </div>
    );

    const renderBeInTouch = () => (
        <div className="HomePage-BeInTouch">
            <div className="HomePage-BeInTouch-Container">
                <div className="HomePage-BeInTouch-Image">
                    <img width="0" height="0" src={ contact_us } alt="Contact us" />
                </div>
                <div className="HomePage-BeInTouch-Content">
                    <div className="HomePage-BeInTouch-Heading">Be <span>In Touch</span></div>
                    <div className="HomePage-BeInTouch-Text">Send or call us with your requirements, we will conduct market research by reviewing your product specifications and the products of your competitors. We will create a prototype at no cost and send you a result! It will be a stunning experience!</div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="HomePage">
            { renderMetaData() }
            { renderAboutSection() }
            { renderServicesSection() }
            { renderWhyusSection() }
            { renderHowWeDoSection() }
            { renderBeInTouch() }
        </div>
    );
};

export default HomePageComponent;
