import React from "react";

import "./Banner.scss";

const BannerComponent = () => (
    <div className="Banner">
        <div className="Banner-Container">
            <h1>The Brand<br /><span>Boosters</span></h1>
        </div>
    </div>
);

export default BannerComponent;