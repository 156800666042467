import React from "react";

import aboutusimage from "../../Assets/images/about_voj.webp";
import website_design from "../../Assets/images/website_design.webp";
import website_development from "../../Assets/images/web_development.webp";
import logo_design from "../../Assets/images/logo_design.webp";
import graphic_design from "../../Assets/images/graphic_design.webp";
import digital_marketing from "../../Assets/images/digital_marketing.webp";
import content_writing from "../../Assets/images/content_writing.webp";

import "./AboutusPage.scss";
import { Helmet } from "react-helmet";

const AboutusPageComponent = () => {
    const renderMetaData = () => (
        <Helmet>
            <title>About Us - VOJ WebTech</title>
            <meta name="description" content="The Brand Boosters About VOJ WebTech VOJ Webtech offers a complete online solution to entrepreneurs, SMEs artists and innovators. Adroit services of Website designing, website developing, blogging, graphic designing, online marketing, SEO, PPC, social media content, online advertisement and much more are in the counts here. Therefore, make yourself meet the world, expand your business." />
            <meta name="robots" content="max-image-preview:large" />
            <link rel="canonical" href="https://www.vojwebtech.com/about-us/" />
        </Helmet>
    );

    const renderAboutSection = () => (
        <div className="AboutusPage-Aboutus">
            <div className="AboutusPage-Aboutus-Container">
                <div className="AboutusPage-Aboutus-Image">
                    <img src={ aboutusimage } alt="About Voj WebTech" width="0" height="0" />
                </div>
                <div className="AboutusPage-Aboutus-Content">
                    <div className="AboutusPage-Aboutus-Heading">About <span>VOJ WebTech</span></div>
                    <div className="AboutusPage-Aboutus-Text">VOJ Webtech offers a complete online solution to entrepreneurs, SMEs artists and innovators. Adroit services of Website designing, website developing, blogging, graphic designing, online marketing, SEO, PPC, social media content, online advertisement and much more are in the counts here. Therefore, make yourself meet the world, expand your business, attain more leads and shine like a dazzling star with our integrated digital services.</div>
                </div>
            </div>
        </div>
    );

    const renderServicesSection = () => (
        <div className="AboutusPage-Services">
            <div className="AboutusPage-Services-Container">
                <div className="AboutusPage-Services-SubHeading">Services</div>
                <div className="AboutusPage-Services-Heading">See What <span>We Can Do</span> For You</div>
                <div className="AboutusPage-Services-Items">
                    <div className="AboutusPage-Services-Item">
                        <img width="0" height="0" src={ website_design } alt="Website Design" className="AboutusPage-Services-Item-Image"/>
                        <div className="AboutusPage-Services-Item-Heading">Website Design</div>
                        <div className="AboutusPage-Services-Item-Text">
                            Every business needs an online face. Therefore,
                             having a wonderful website can make a huge profit to your business.
                        </div>
                    </div>
                    <div className="AboutusPage-Services-Item">
                        <img width="0" height="0" src={ website_development } alt="Web Application" className="AboutusPage-Services-Item-Image"/>
                        <div className="AboutusPage-Services-Item-Heading">Web Application</div>
                        <div className="AboutusPage-Services-Item-Text">
                            For boosting a business in this competitive world,
                             it should have an online face. Having a website helps
                              to make an online debut to your business.
                        </div>
                    </div>
                    <div className="AboutusPage-Services-Item">
                        <img width="0" height="0" src={ graphic_design } alt="Graphic Design" className="AboutusPage-Services-Item-Image"/>
                        <div className="AboutusPage-Services-Item-Heading">Graphic Design</div>
                        <div className="AboutusPage-Services-Item-Text">
                            Here is the designing solutions for your online and offline graphics and artistics work.
                        </div>
                    </div>
                    <div className="AboutusPage-Services-Item">
                        <img width="0" height="0" src={ digital_marketing } alt="Digital Marketing" className="AboutusPage-Services-Item-Image"/>
                        <div className="AboutusPage-Services-Item-Heading">Digital Marketing</div>
                        <div className="AboutusPage-Services-Item-Text">
                            It is not enough to just have a website and develop it to be in action.
                             Your business needs more. Therefore, we provide digital marketing 
                             services to help your business growth.
                        </div>
                    </div>
                    <div className="AboutusPage-Services-Item">
                        <img width="0" height="0" src={ content_writing } alt="Content Writing" className="AboutusPage-Services-Item-Image"/>
                        <div className="AboutusPage-Services-Item-Heading">Content Writing</div>
                        <div className="AboutusPage-Services-Item-Text">
                            We provide the effective, meaningful and noticeable
                             content for your websites. For ranking at the topmost 
                             counts in Search Engines, we can help your business to grab attention of customers.
                        </div>
                    </div>
                    <div className="AboutusPage-Services-Item">
                        <img width="0" height="0" src={ logo_design } alt="Logo Design" className="AboutusPage-Services-Item-Image"/>
                        <div className="AboutusPage-Services-Item-Heading">Logo Design</div>
                        <div className="AboutusPage-Services-Item-Text">
                            Your logo is the first image of your business. You can begin a new venture at this right place with a great looking and professional logo.
                        </div>
                    </div>
                </div>
                <div className="AboutusPage-Services-Text">
                    It takes all these services at a huge scale to elevate any business. 
                    So shine brightly and think <span>OUTSIDE THE BOX</span> with <span>VOJ Webtech</span>. 
                    Competitiveness brings great opportunities to every entrepreneur. 
                    Promote your products or services to the world, become the top businessman/businesswomen, 
                    attain more leads and boost your business growth.
                </div>
            </div>
        </div>
    );

    return (
        <div className="AboutusPage">
            { renderMetaData() }
            { renderAboutSection() }
            { renderServicesSection() }
        </div>
    );
};

export default AboutusPageComponent;
