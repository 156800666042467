import React from "react";

import "./ContactusPage.scss";
import { Helmet } from "react-helmet";

const ContactusPageComponent = () => {
    const renderMetaData = () => (
        <Helmet>
            <title>Contact Us - VOJ WebTech</title>
            <meta name="description" content="The Brand Boosters Contact Us +917988749739 info@vojwebtech.com Be in touch Send or call us with your requirements, we will conduct market research by reviewing your product specifications and the products of your competitors. We will create a prototype at no cost and send you a result! It will be a stunning experience!" />
            <meta name="robots" content="max-image-preview:large" />
            <link rel="canonical" href="https://www.vojwebtech.com/contact-us/" />
        </Helmet>
    );

    const renderHeading = () => (
        <div className="ContactusPage-Heading">Get in Touch with Us</div>
    );

    const renderSubHeading = () => (
        <div className="ContactusPage-SubHeading">We&apos;re here to help you with any questions or concerns</div>
    );

    const renderText = () => (
        <div className="ContactusPage-Content">Whether you have questions about our products or services, need assistance with your account, or simply want to share your feedback, we&apos;re always here to listen. You can contact us via email or phone, and we&apos;ll get back to you as soon as possible.</div>
    );

    return (
        <div className="ContactusPage">
            { renderMetaData() }
            { renderHeading() }
            { renderSubHeading() }
            { renderText() }
        </div>
    );
};

export default ContactusPageComponent;