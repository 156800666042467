import React from "react";
import { Helmet } from "react-helmet";

import website_design from "../../Assets/images/website_design.webp";
import web_development from "../../Assets/images/web_development.webp";
import graphic_design from "../../Assets/images/graphic_design.webp";
import digital_marketing from "../../Assets/images/digital_marketing.webp";
import content_writing from "../../Assets/images/content_writing.webp";
import logo_design from "../../Assets/images/logo_design.webp";

import "./WebsiteDesigningPage.scss";

const WebsiteDesigningPageComponent = () => {
    const renderMetaData = () => (
        <Helmet>
            <title>Web Design Services |Website Design Comapany - Vojwebtech</title>
            <meta name="description" content="Vojwebtech is a web design company in India that offers complete website design services. We have experts who provide creative web design solutions. Visit Now!" />
            <meta name="robots" content="max-image-preview:large" />
            <link rel="canonical" href="https://www.vojwebtech.com/website-designing/" />
        </Helmet>
    );

    const WebsiteDesigningPageHeading = () => (
        <div className="WebsiteDesigningPage-Heading">
            Website Designing : <span>VOJ WebTech</span>
        </div>
    );

    const WebsiteDesigningPageContent = () => (
        <div>
            <div className="WebsiteDesigningPage-Content ">
                We design websites that meet the goals and objectives of our clients and clients’ target audiences through a variety of site creation services.
            </div>

            <div className="WebsiteDesigningPage-Content">
                VOJWebtech believes in catering advanced services and solutions to our clients. We like to design the websites in a way that also gets loved. We also strive hard to provide customers and clients with an online look to stand out from the crowd. Moreover, our web designers are adroit in creating and making a valuable impression on any business.
            </div>

            <div className="WebsiteDesigningPage-Content">
                VOJWebtech develops sorted web designs that are user-friendly, compatible with all devices, and a worthy expression of your business. Moreover, adaptability and customizations are the true spirits of our work. We are very much keen to build such websites which have been proven to be loved by clients.
            </div>

            <div className="WebsiteDesigningPage-Content">
                We are familiar with all aspects of making a website a masterpiece. Our experienced team of website designers and artists focuses on building designs that allure online viewers. Moreover, which further converts viewers into customers and then into long-term clients. Therefore, our website designing services and skills will add charm to your digital presence.
            </div>
        </div>
    );

    const WebsiteDesigningPageSubItem = () => (
        <div className="WebsiteDesigningPage-Items">
            <div className="WebsiteDesigningPage-SubHeading">
                See What <span>Our Web Designers</span> Can Do For You
            </div>

            <div className="WebsiteDesigningPage-SubItems">
                <div className="WebsiteDesigningPage-Item">
                    <div className="WebsiteDesigningPage-Item-Img">
                        <img width="0" height="0" src={website_design} alt="Customized Web Design" />
                    </div>
                    <div className="WebsiteDesigningPage-Item-Heading">
                        <span>Customized Web Design</span>
                    </div>
                    <div className="WebsiteDesigningPage-Item-Content">
                        Two actions are needed here vice versa. And, that is your imagination and our creation. Yes, if you can imagine the way you want your website to be built. Then, we can possibly make it, create it, and design it according to your needs. Not only just according to your needs but also, along with your expectations. Our expert website designers are much capable of bringing innovative ideas and reality to your website designs. Therefore, with VOJ web tech you will not find any need to speak for your business, your websites will do it for you.
                    </div>
                </div>

                <div className="WebsiteDesigningPage-Item">
                    <div className="WebsiteDesigningPage-Item-Img">
                        <img width="0" height="0" src={web_development} alt="Design Template" />
                    </div>
                    <div className="WebsiteDesigningPage-Item-Heading">
                        <span>Design Template</span>
                    </div>
                    <div className="WebsiteDesigningPage-Item-Content">
                        Basic and advanced templates for designing a website are in proper customization in our available services. These designing templates are made fortunately to meet and greet your business ideas. Therefore, for personalizing the image of your brand implementation of designing templates will make the online presence of your business more amazing.
                    </div>
                </div>

                <div className="WebsiteDesigningPage-Item">
                    <div className="WebsiteDesigningPage-Item-Img">
                        <img width="0" height="0" src={graphic_design} alt="Design Template" />
                    </div>
                    <div className="WebsiteDesigningPage-Item-Heading">
                        <span>Responsive Web Design</span>
                    </div>
                    <div className="WebsiteDesigningPage-Item-Content">
                        Compatibility of websites that not only work on desktops or computers but also on mobile versions and every other device is the first step to look before designing websites. Therefore, mobile responsiveness, desktop responsiveness, and much more devices suitability are mandatory in site creation for easy accessibility among viewers.
                    </div>
                </div>

                <div className="WebsiteDesigningPage-Item">
                    <div className="WebsiteDesigningPage-Item-Img">
                        <img width="0" height="0" src={digital_marketing} alt="Design Template" />
                    </div>
                    <div className="WebsiteDesigningPage-Item-Heading">
                        <span>Redesigning of websites</span>
                    </div>
                    <div className="WebsiteDesigningPage-Item-Content">
                        We make ourselves updated with the modernization and changes in designing technologies and trends. If you want to give your business a new outlook, redesigning website services can possibly bring your business in a more appropriate online position.
                    </div>
                </div>

                <div className="WebsiteDesigningPage-Item">
                    <div className="WebsiteDesigningPage-Item-Img">
                        <img width="0" height="0" src={content_writing} alt="Design Template" />
                    </div>
                    <div className="WebsiteDesigningPage-Item-Heading">
                        <span>SEO Friendly sites</span>
                    </div>
                    <div className="WebsiteDesigningPage-Item-Content">
                        Every business or any company always looks for website designs that are easy in accessing and SEO-friendly. It means user-friendly designs of sites are loved by viewers, customers, visitors, clients, and even also by search engine crawlers.
                    </div>
                </div>

                <div className="WebsiteDesigningPage-Item">
                    <div className="WebsiteDesigningPage-Item-Img">
                        <img width="0" height="0" src={logo_design} alt="Design Template" />
                    </div>
                    <div className="WebsiteDesigningPage-Item-Heading">
                        <span>Browser Compatible sites</span>
                    </div>
                    <div className="WebsiteDesigningPage-Item-Content"> 
                        In addition to other aspects, website designs are suitable enough to work across several browsers and search engines. Therefore, multiple browsers, Internet Explorer, Firefox, Safari and many more are must to keep in sight before designing websites.
                    </div>
                </div>
            </div>        

            
        </div>
    );

    return (
        <div className="WebsiteDesigningPage">
            { renderMetaData() }
            { WebsiteDesigningPageHeading() }
            { WebsiteDesigningPageContent() }
            { WebsiteDesigningPageSubItem() }
        </div>
    );
};

export default WebsiteDesigningPageComponent;
