import React, { useState } from "react";
import HeaderComponent from "./Header.component";

const HeaderContainer = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const onMenuIconClick = () => {
        setMenuOpen(!menuOpen);
    };

    const containerProps = {
        onMenuIconClick,
        menuOpen
    };

    return (
        <HeaderComponent { ...containerProps }/>
    );
};

export default HeaderContainer;