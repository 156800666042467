import React from "react";
import { FaCheck, FaCompactDisc, FaLink, FaPaintBrush, FaRegChartBar, FaRegFileCode, FaRegLightbulb, FaRocket } from "react-icons/fa";

import "./DigitalMarketingPage.scss";
import { Helmet } from "react-helmet";

const DigitalMarketingPageComponent = () => {
    const renderMetaData = () => (
        <Helmet>
            <title>Best SEO Company in Chandigarh | Digital Marketing Services - Vojwebtech</title>
            <meta name="description" content="Vojwebtech is a professional SEO company in Chandigarh. We provide the best digital marketing services to the leading brands in India. Get a quote now!" />
            <meta name="robots" content="max-image-preview:large" />
            <link rel="canonical" href="https://www.vojwebtech.com/digital-marketing/" />
        </Helmet>
    );

    const renderHeading = () => (
        <div className="DigitalMarketingPage-Heading">
            Digital marketing : <span>VOJ WebTech</span>
        </div>
    );

    const renderServicesSection = () => (
        <div className="DigitalMarketingPage-ServicesContent">
            <div className="DigitalMarketingPage-ServicesContent-Container">
                <div className="DigitalMarketingPage-ServicesContent-Content">
                    <div className="DigitalMarketingPage-ServicesContent-Start" />
                    <div className="DigitalMarketingPage-ServicesContent-Items">
                        <div className="DigitalMarketingPage-ServicesContent-Item">
                            <div className="DigitalMarketingPage-ServicesContent-Item-Label">01</div>
                            <div className="DigitalMarketingPage-ServicesContent-Item-Icon"><FaRegLightbulb /></div>
                            <div className="DigitalMarketingPage-ServicesContent-Item-Arrow" />
                            <div className="DigitalMarketingPage-ServicesContent-Item-Content">
                                <div className="DigitalMarketingPage-ServicesContent-Item-Content-Heading">Consult</div>
                                <div className="DigitalMarketingPage-ServicesContent-Item-Content-Text">Our Digital Marketing experts are always at your service. You can share your queries, explore with us and take an appropriate decision.</div>
                            </div>
                        </div>
                        <div className="DigitalMarketingPage-ServicesContent-Item">
                            <div className="DigitalMarketingPage-ServicesContent-Item-Label">02</div>
                            <div className="DigitalMarketingPage-ServicesContent-Item-Icon"><FaRegChartBar /></div>
                            <div className="DigitalMarketingPage-ServicesContent-Item-Arrow" />
                            <div className="DigitalMarketingPage-ServicesContent-Item-Content">
                                <div className="DigitalMarketingPage-ServicesContent-Item-Content-Heading">Keywords research</div>
                                <div className="DigitalMarketingPage-ServicesContent-Item-Content-Text">Keywords are the basis of Digital Marketing campaigns. Let our Digital Marketing Executives find out the best keywords for your business. Our experienced team will show its hidden potential with such in-depth knowledge of keywords planning and implementation.</div>
                            </div>
                        </div>
                        <div className="DigitalMarketingPage-ServicesContent-Item">
                            <div className="DigitalMarketingPage-ServicesContent-Item-Label">03</div>
                            <div className="DigitalMarketingPage-ServicesContent-Item-Icon"><FaPaintBrush /></div>
                            <div className="DigitalMarketingPage-ServicesContent-Item-Arrow" />
                            <div className="DigitalMarketingPage-ServicesContent-Item-Content">
                                <div className="DigitalMarketingPage-ServicesContent-Item-Content-Heading">Website Audit</div>
                                <div className="DigitalMarketingPage-ServicesContent-Item-Content-Text">With this service you can find out how your website is performing and what more is required to improve its optimization score. Therefore, attract that natural traffic of viewers.</div>
                            </div>
                        </div>
                        <div className="DigitalMarketingPage-ServicesContent-Item">
                            <div className="DigitalMarketingPage-ServicesContent-Item-Label">04</div>
                            <div className="DigitalMarketingPage-ServicesContent-Item-Icon"><FaRegFileCode /></div>
                            <div className="DigitalMarketingPage-ServicesContent-Item-Arrow" />
                            <div className="DigitalMarketingPage-ServicesContent-Item-Content">
                                <div className="DigitalMarketingPage-ServicesContent-Item-Content-Heading">On- page SEO Optimization</div>
                                <div className="DigitalMarketingPage-ServicesContent-Item-Content-Text">Complete on-page optimization with title tags, html tags, interlinking, images, meta tags, schema, markups and many more are 100% perfectly delivered digital marketing solutions to your website.</div>
                            </div>
                        </div>
                        <div className="DigitalMarketingPage-ServicesContent-Item">
                            <div className="DigitalMarketingPage-ServicesContent-Item-Label">05</div>
                            <div className="DigitalMarketingPage-ServicesContent-Item-Icon"><FaRocket /></div>
                            <div className="DigitalMarketingPage-ServicesContent-Item-Arrow" />
                            <div className="DigitalMarketingPage-ServicesContent-Item-Content">
                                <div className="DigitalMarketingPage-ServicesContent-Item-Content-Heading">Content Optimization</div>
                                <div className="DigitalMarketingPage-ServicesContent-Item-Content-Text">Content is the head of the whole digital marketing process. Nothing more is better than a good site friendly content. We can give you an advanced level of content writing structure with fresh and trending keywords.</div>
                            </div>
                        </div>
                        <div className="DigitalMarketingPage-ServicesContent-Item">
                            <div className="DigitalMarketingPage-ServicesContent-Item-Label">06</div>
                            <div className="DigitalMarketingPage-ServicesContent-Item-Icon"><FaLink /></div>
                            <div className="DigitalMarketingPage-ServicesContent-Item-Arrow" />
                            <div className="DigitalMarketingPage-ServicesContent-Item-Content">
                                <div className="DigitalMarketingPage-ServicesContent-Item-Content-Heading">Link building and off page Optimization</div>
                                <div className="DigitalMarketingPage-ServicesContent-Item-Content-Text">Every business website needs backlinks for an equal competition online. And, these should not be just links but must be good links. Therefore, generating good links for you to get more high ranks on optimization platforms is the skill in our experienced digital marketing team.</div>
                            </div>
                        </div>
                        <div className="DigitalMarketingPage-ServicesContent-Item">
                            <div className="DigitalMarketingPage-ServicesContent-Item-Label">07</div>
                            <div className="DigitalMarketingPage-ServicesContent-Item-Icon"><FaCompactDisc /></div>
                            <div className="DigitalMarketingPage-ServicesContent-Item-Arrow" />
                            <div className="DigitalMarketingPage-ServicesContent-Item-Content">
                                <div className="DigitalMarketingPage-ServicesContent-Item-Content-Heading">Website conversion optimisation</div>
                                <div className="DigitalMarketingPage-ServicesContent-Item-Content-Text">Alluring titles, amazing designs, adorable layouts and awesome landing pages are very much beneficial in rocketing high your website ranks and performance among viewers.</div>
                            </div>
                        </div>
                    </div>
                    <div className="DigitalMarketingPage-ServicesContent-End" />
                </div>
            </div>
        </div>
    );

    return (
        <div className="DigitalMarketingPage">
            { renderMetaData() }
            <div className="DigitalMarketingPage-Container">
                { renderHeading() }
                <p>VOJ Webtech provides advanced quality Digital Marketing services which get your business at the top rankings. Moreover, driving more traffic to websites may help to get more leads also. Our digital marketing services emphasise on digital presence. And, it is not just an online presence but a top class presence.</p>
                <p>Digital marketing is for maintaining websites in a good manner so that it becomes more information oriented. Digital Marketing limits the benchmarks for various website performances. The easier it is to find a website, the best will be your business.</p>
                <p>Digital Marketing makes sure that your website ranks appropriately on search engines. Hence, it lures more customers. A high boost in traffic leads to more business profit. Therefore, marketing your website on a digital platform is a vital business tactic.</p>
                <p>Digital Marketing is quite complex to do. One needs complete knowledge of Search Engine crawlers, website development, demands of users. Experts of VOJWebtech can take care of all this for your business progress.</p>
                <p>VOJ webtech is expertise in optimising sites to get on topmost rankings. Our Digital Marketing services are admired in propelling the digital presence of websites to an extreme level. The more your website is on top rank, the more profit you grab. We can make it possible for you by:</p>
                <div className="DigitalMarketingPage-CheckList">
                    <div><FaCheck /><span> Delivering amazing results.</span></div>
                    <div><FaCheck /><span> Free audit.</span></div>
                    <div><FaCheck /><span> SEO Optimization.</span></div>
                    <div><FaCheck /><span> Getting high leads.</span></div>
                    <div><FaCheck /><span> Focused strategies.</span></div>
                    <div><FaCheck /><span> One go solutions and regular analysis.</span></div>
                    <div><FaCheck /><span> AI tools.</span></div>
                </div>
            </div>
            <div className="DigitalMarketingPage-Services">
                <div className="DigitalMarketingPage-Container">
                    <div className="DigitalMarketingPage-Heading">
                        See What Our <span>Digital Marketing Services</span> Can Do For You
                    </div>
                    { renderServicesSection() }
                </div>
            </div>
        </div>
    );
};

export default DigitalMarketingPageComponent;